.header-title {
    padding: 17px 10px 17px 20px;
    border-bottom: 1px solid #303234;

    h4 {
        color: #bdbebe;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        border-left: 2px solid #5b52e7;
        padding: 0px 0px 0px 10px;
        margin: 0;
    }

    font-size: 12px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    opacity: 0.5;
}



@keyframes loading {
  0% { background-color: #111212; }
  50% { background-color: #000000; }
  100% { background-color: #111212; }
}
